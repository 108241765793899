@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}
.no-tailwind ol,
.no-tailwind ul {
  list-style: inherit;
  margin: initial;
  padding: initial;
  padding-inline-start: 40px;
}

.no-tailwind blockquote,
.no-tailwind dl,
.no-tailwind dd,
.no-tailwind h1,
.no-tailwind h2,
.no-tailwind h3,
.no-tailwind h4,
.no-tailwind h5,
.no-tailwind h6,
.no-tailwind hr,
.no-tailwind figure,
.no-tailwind p,
.no-tailwind pre {
  margin: 12px 0px;
}

.group:hover .calendar-background-image {
  background-size: 115% 115% !important;
}

.bg-image-liquid-cheese {
  background-image: url(/liquid-cheese.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  background-color: transparent;
}

.gradient {
  background-color: #02162D;
}

.gradient2 {
  background-color: #5567ff;
  background-image: linear-gradient(315deg, #5567ff 0%, #6774ea 74%);
}


/* Browser mockup code
* Contribute: https://gist.github.com/jarthod/8719db9fef8deb937f4f
* Live example: https://updown.io
*/

.browser-mockup {
  border-top: 2em solid rgba(230, 230, 230, 0.7);
  position: relative;
  height: 100vh;
  background-image: url(https://kreezus.com/wp-content/uploads/2022/05/Capture_decran_2022-05-26_a_10.27.47.png);
}
.DetailSection {
  background-color: #fbf9ff;
}


.browser-mockup:before {
  display: block;
  position: absolute;
  content: "";
  top: -1.25em;
  left: 1em;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background-color: #f44;
  box-shadow: 0 0 0 2px #f44, 1.5em 0 0 2px #9b3, 3em 0 0 2px #fb5;
}

.browser-mockup > * {
  display: block;
}